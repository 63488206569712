import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class FabricsFactoryProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_MER_API)
  }

  getAll (query) {
    this.setHeader(getAuthToken())
    return this.get('/fabric-stores', query)
  }

  getById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/fabric-stores/${id}`)
  }

  createOne (form) {
    this.setHeader(getAuthToken())
    return this.post('/fabric-stores', form)
  }

  updateOne (id, form) {
    this.setHeader(getAuthToken())
    return this.put(`/fabric-stores/${id}`, form)
  }

  deleteOne (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/fabric-stores/${id}`)
  }
}

export default FabricsFactoryProvider

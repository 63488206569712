<template>
  <v-container
    class="fabric-form-container rounded elevation-3"
    fluid>
    <h2>
      {{ isEdit ? 'Edit Fabric' : 'New Fabric' }}
    </h2>
    <v-form
      ref="fabric-form"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col
          cols="12"
          md="6">
          <gw-drop-file
            v-model="formData.imageUrls"
            :multiple="true"
            need-compress
            class="fabric-drop-file"
            s3-path="/fabric-images"
            caption="คลิกเพื่อเพิ่มรูปภาพ"
            :limit="10"
            label-bold />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-row class="mt-5">
            <v-col cols="6">
              <v-text-field
                v-model.trim="formData.sketchId"
                label="Sketch Id"
                hide-details
                outlined
                dense
                @input="toUpperCase(formData.sketchId, 'sketchId')" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.trim="formData.hangId"
                label="Hang Id*"
                hide-details
                outlined
                dense
                :rules="textBoxRules"
                @input="toUpperCase(formData.hangId, 'hangId')" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.trim="formData.fabricNo"
                label="Fabric Id*"
                hide-details
                outlined
                dense
                :rules="textBoxRules"
                @input="toUpperCase(formData.fabricNo, 'fabricNo')" />
            </v-col>
            <v-col cols="6">
              <v-combobox
                v-model.trim="formData.factory"
                :items="factory"
                label="Factory*"
                single
                outlined
                deletable-chips
                small-chips
                dense
                hide-details
                :rules="textBoxRules"
                class="uppercase"
                @input="toUpperCase(formData.factory, 'factory')" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.trim="formData.color"
                label="Color"
                hide-details
                outlined
                dense
                @input="toUpperCase(formData.color, 'color')" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.trim="formData.size"
                label="Size"
                hide-details
                outlined
                dense
                @input="toUpperCase(formData.size, 'size')" />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model.trim="formData.collectionName"
                label="Collection"
                hide-details
                outlined
                dense
                @input="toUpperCase(formData.collectionName, 'collectionName')" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model.number="formData.unitPrice"
                type="number"
                label="Unit Price"
                hide-details
                outlined
                dense />
            </v-col>
            <v-col cols="6">
              <v-radio-group
                v-model.trim="formData.currency"
                label="Currency"
                row
                hide-details
                dense
                class="mt-1">
                <v-radio
                  label="บาท"
                  value="thb"
                  color="secondary" />
                <v-radio
                  label="หยวน"
                  value="cny"
                  color="secondary" />
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model.trim="formData.note"
                label="Note"
                dense
                hide-details
                outlined />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col
          cols="12"
          class="d-flex justify-center">
          <v-btn
            class="ml-1"
            type="submit"
            color="success"
            :loading="loading">
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FabricsProvider from '@/resources/FabricsProvider'
import FabricsFactoryProvider from '@/resources/FabricsFactoyProvider'

const FabricsService = new FabricsProvider()
const FabricsFactoyService = new FabricsFactoryProvider()

export default {
  data () {
    return {
      loading: false,
      isEdit: false,
      validateImg: false,
      factoryData: [],
      formData: {
        sketchId: '',
        hangId: '',
        fabricNo: '',
        imageUrls: [],
        factory: '',
        color: '',
        size: '',
        unitPrice: 0,
        currency: 'thb',
        collectionName: '',
        note: '',
        status: 'active'
      },
      textBoxRules: [
        (v) => !!v || 'Field is require!'
      ],
      numberBoxRules: [
        (v) => v >= 0 || 'Please enter more than 0'
      ]
    }
  },
  computed: {
    factory: {
      get () {
        const results = this.factoryData.map((item) => item.name)
        return results
      },
      set (newVal) {
        const newValue = newVal[0] ? newVal[0].trim().toLowerCase() : ''
        this.formData.factory = newValue
      }
    }
  },
  mounted () {
    if (this.$route.params?.id) {
      this.isEdit = true
      this.getFabric()
    }
    this.getFabricFactory()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    async getFabric () {
      try {
        this.loading = true
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'LOADING DATA...'
        })

        const { data } = await FabricsService.getById(this.$route.params.id)
        this.formData = data
      } catch (error) {
        console.error('getFabric', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.setLoading({ active: false })
      }
    },
    async getFabricFactory () {
      try {
        this.loading = true
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'LOADING DATA...'
        })

        const { data } = await FabricsFactoyService.getAll({ limit: 9999 })
        this.factoryData = data.results
      } catch (error) {
        console.error('getFabricFactory', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.setLoading({ active: false })
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'PROCESSING...'
        })
        const valid = await this.$refs['fabric-form'].validate()
        if (valid) {
          if (this.isEdit) {
            await FabricsService.updateOne(this.$route.params.id, this.formData)
            this.setSnackbar({
              value: true,
              message: 'Update fabric success',
              type: 'success'
            })
          } else {
            await FabricsService.createOne(this.formData)
            this.setSnackbar({
              value: true,
              message: 'Create fabric success',
              type: 'success'
            })
          }
          this.$router.push({ name: 'FabricsList' })
        }
      } catch (error) {
        console.error('onSubmit', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.setLoading({ active: false })
      }
    },
    toUpperCase (val, key) {
      this.formData[key] = val.toUpperCase() || ''
    }
  }
}
</script>

<style scoped>
.fabric-form-container {
  background-color: white;
}
.cursor-pointer {
  cursor: pointer;
}
.fabric-drop-file .upload-file {
  height: 258px;
}
.uppercase input {
  text-transform: uppercase;
}
</style>
